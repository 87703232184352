import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { isAdmin } from '../../lib/auth/authService'
import history from '../../lib/history'
import { getOrganization } from '../../data/api/organization'
import { apiToUserDto, getMe } from '../../data/api/users'
import { useAuth } from '../../hooks/auth-context'
import { useOrganization } from '../../hooks/organization-context'
import auth from '../../lib/auth/localStorage'
import { isApiResultSuccess } from '../../lib/axios'
import { AuthRoutes } from '../../lib/route-builder'

const AdminRoute = ({ ...props }) => {
  const { setUser, user } = useAuth()
  const { setOrganization } = useOrganization()

  useEffect(() => {
    if (auth.getUserId()) {
      getMe().then((result) => {
        if (isApiResultSuccess(result)) {
          if (isAdmin()) {
            setUser(apiToUserDto(result.data))
          } else {
            history.push(AuthRoutes.login())
          }
        }
      })
    } else {
      history.push(AuthRoutes.login())
    }
    if (auth.getOrganizationId()) {
      getOrganization(auth.getOrganizationId()!!).then((result) => {
        if (isApiResultSuccess(result)) {
          setOrganization(result.data)
        }
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (user) {
    return <Route {...props} />
  }
  return <div />
}

export default AdminRoute
