import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl'
import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SWRConfig } from 'swr'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import loadable from '@loadable/component'
import { DelayedLoader } from './components/loader'
import AdminRoute from './components/private-route/admin'
import OperatorRoute from './components/private-route/operator'
import { AuthProvider } from './hooks/auth-context'
import { OrganizationProvider } from './hooks/organization-context'
import { periodicRefresh } from './lib/auth/authService'
import { jsonFetcher } from './lib/axios'
import loadModule, { loadAuthModule } from './components/load-module'
import ToasterDefault from './lib/hot-toast/default'
import { AuthRoutes } from './lib/route-builder'
import history from './lib/history'

const AuthSection = loadAuthModule(() => import('./pages/auth'))
const AdminSection = loadModule(() => import('./pages/admin'))
const OperatorSection = loadModule(() => import('./pages/operator'))

const NotFound = loadable(() => import('./pages/not-found'))

function Routes() {
  const { i18n } = useTranslation()
  return (
    <AuthProvider>
      <OrganizationProvider>
        <LocalizationProvider language={i18n.language}>
          <IntlProvider locale={i18n.language === 'fr' ? 'fr-CA' : i18n.language}>
            <Router history={history}>
              <Switch>
                <Route exact path="/">
                  <Redirect to={AuthRoutes.login()} />
                </Route>
                <Route path="/auth" component={AuthSection} />
                <AdminRoute path="/admin" component={AdminSection} />
                <OperatorRoute path="/operator" component={OperatorSection} />
                <Route path="*" component={NotFound}>
                  <NotFound />
                </Route>
              </Switch>
            </Router>
          </IntlProvider>
        </LocalizationProvider>
      </OrganizationProvider>
      <ToasterDefault />
    </AuthProvider>
  )
}

function App() {
  useEffect(() => {
    periodicRefresh()
  }, [])

  return (
    <Suspense fallback={<DelayedLoader />}>
      <SWRConfig
        value={{
          fetcher: jsonFetcher,
        }}
      >
        <Routes />
      </SWRConfig>
    </Suspense>
  )
}

export default App
