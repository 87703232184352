import React, { useContext, useState } from 'react'
import { ApiOrganizationDto } from '../data/api/organization'

const OrganizationContext = React.createContext<OrganizationState>({} as OrganizationState)

interface OrganizationState {
  organization?: ApiOrganizationDto
  setOrganization: (organization: ApiOrganizationDto) => void
  clearOrganization: () => void
}

export const OrganizationProvider = ({ children }: { children: React.ReactNode }) => {
  const [organization, setOrganization] = useState<ApiOrganizationDto>()
  const context = {
    organization,
    setOrganization,
    clearOrganization: () => setOrganization(undefined),
  }

  return <OrganizationContext.Provider value={context}>{children}</OrganizationContext.Provider>
}

export const useOrganization = () => useContext(OrganizationContext)
