import React from 'react'
import { Loader } from '@progress/kendo-react-indicators'

export const DelayedLoader = () => {
  const delay = 1000
  const [ready, setReady] = React.useState(false)
  React.useEffect(() => {
    let timeout: any = null
    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return ready ? <Loader type="infinite-spinner" size="large" themeColor="primary" /> : null
}
