export const AuthRoutes = {
  root: () => '/auth',
  login: () => '/auth/login',
  resetPassword: ({ token, userId }: { token: string; userId: string }) =>
    `/auth/reset-password?token=${token}&userId=${userId}`,
  forgotPassword: () => '/auth/forgot-password',
  emailSent: () => '/auth/forgot-password-email-sent',
  invitation: ({
    token,
    userId,
    expiresAt,
  }: {
    token: string
    userId: string
    expiresAt: string
  }) => `/auth/invitation?token=${token}&userId=${userId}&expiresAt=${expiresAt}`,
}

export const AdminRoutes = {
  root: () => '/admin',
  organizationList: () => '/admin/organizations',
  siteList: (organizationId: string) => `/admin/${organizationId}/sites`,
  site: ({ organizationId, siteId }: { organizationId: string; siteId: string }) =>
    `/admin/site?organizationId=${organizationId}&siteId=${siteId}`,
  addSite: (organizationId: string) => `/admin/${organizationId}/sites/add`,
  cluster: ({
    clusterId,
    siteId,
    organizationId,
  }: {
    clusterId: string
    siteId: string
    organizationId: string
  }) => `/admin/cluster?organizationId=${organizationId}&siteId=${siteId}&clusterId=${clusterId}`,
  addCluster: (organizationId: string, siteId: string) =>
    `/admin/${organizationId}/site/${siteId}/addCluster`,
  station: ({
    stationId,
    clusterId,
    siteId,
    organizationId,
  }: {
    stationId: string
    clusterId: string
    siteId: string
    organizationId: string
  }) =>
    `/admin/station?organizationId=${organizationId}&siteId=${siteId}&clusterId=${clusterId}&stationId=${stationId}`,
  addStation: (organizationId: string, siteId: string, clusterId: string) =>
    `/admin/${organizationId}/site/${siteId}/cluster/${clusterId}/addStation`,
  vehicle: ({ organizationId, vehicleId }: { organizationId: string; vehicleId: string }) =>
    `/admin/vehicle?organizationId=${organizationId}&vehicleId=${vehicleId}`,
  vehicleList: (organizationId: string) => `/admin/${organizationId}/vehicles`,
  addVehicle: (organizationId: string) => `/admin/${organizationId}/vehicles/add`,
  userList: () => '/admin/users',
  userProfile: (userId: string) => `/admin/users/user?userId=${userId}`,
  myProfile: () => '/admin/profile',
  userAdd: () => '/admin/users/add',
  operatorList: (organizationId: string) => `/admin/${organizationId}/operators`,
  operator: ({ organizationId, operatorId }: { organizationId: string; operatorId: string }) =>
    `/admin/operator?organizationId=${organizationId}&operatorId=${operatorId}`,
  addOperator: (organizationId: string) => `/admin/${organizationId}/operators/add`,
}

export const OperatorRoutes = {
  calendar: () => '/operator/calendar',
  myProfile: () => '/operator/profile',
  stationList: () => '/operator/stations',
}
